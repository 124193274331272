import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import star1 from "../../images/stars/star_1.png";
import star2 from "../../images/stars/star_2.png";
import star3 from "../../images/stars/star_3.png";
import star4 from "../../images/stars/star_4.png";
import star5 from "../../images/stars/star_5.png";
import "./FormPage.scss";
import { CustomYellowHoverBtn } from "../Buttons/CustomYellowHoverBtn/CustomYellowHoverBtn";
import { toast } from "react-toastify";

export function FormPage() {
  const navigate = useNavigate();

  const [twitterLink, setTwitterLink] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  // const [twitterLinkValid, setTwitterLinkValid] = useState<boolean>(true);
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateTwitterLink = (link: string): boolean => {
    return link.length > 0;
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleTwitterLinkChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const link = e.target.value;
    setTwitterLink(link);
    // setTwitterLinkValid(validateTwitterLink(link));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const email = e.target.value;
    setEmail(email);
    setEmailValid(validateEmail(email));
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const isTwitterLinkValid = validateTwitterLink(twitterLink);
    const isEmailValid = validateEmail(email);
    // setTwitterLinkValid(isTwitterLinkValid);
    setEmailValid(isEmailValid);

    if (isTwitterLinkValid && isEmailValid) {
      const twitterNickname = twitterLink;
      const referralCode = sessionStorage.getItem('referralCode') || '';

      try {
        const response = await axios.post('https://xfluencersserver-production.up.railway.app/api/register', {
          email,
          twitterLink,
          referralCode
        });

        if (response.data.redirectTo) {
          sessionStorage.setItem('twitterUser', twitterNickname);
          navigate(response.data.redirectTo);
        } else {
          setEmailSent(true);
          toast.success('Check your email to confirm your registration!');
        }
      } catch (error) {
        console.error('There was an error sending the confirmation email:', error);
      }
    }
  };


  return (
    <div className="form-section">
      <div className="container">
        <div className="form-section_container">
          <div className="stars">
            <img src={star1} alt="Star 1" className="star star1" />
            <img src={star2} alt="Star 2" className="star star2" />
            <img src={star3} alt="Star 3" className="star star3" />
            <img src={star4} alt="Star 4" className="star star4" />
            <img src={star5} alt="Star 5" className="star star5" />
          </div>
          <div className="form-section_container-block">
            <div className="form-section_container-block_title">
              <h2>Welcome, Xfluencer!</h2>
            </div>
            <div className="form-section_container-block_subtitle">
              <p>
                Please, provide the link to your Twitter link and email to
                proceed
              </p>
            </div>
            <div className="form-section_container-block_form">
              <div className="form-section_container-block_form-main">
                <div className="input-container">
                  <span className="twitter-link">https://x.com/</span>
                  <input
                    type="text"
                    value={twitterLink}
                    onChange={handleTwitterLinkChange}
                    className={"username-twitter"}
                  />
                </div>
                {/* {!twitterLinkValid && (
                  <p className="error-message">Invalid Twitter link format</p>
                )} */}
              </div>

              <div>
                <input
                  type="text"
                  placeholder="Your Email"
                  value={email}
                  onChange={handleEmailChange}
                  className={emailValid ? "" : "invalid"}
                />
                {!emailValid && (
                  <p className="error-message">Invalid email format</p>
                )}
              </div>
            </div>
            <div className="form-section_container-block_button">
              <CustomYellowHoverBtn onClick={handleSubmit} isDisabled={emailSent}>
              Submit
              </CustomYellowHoverBtn>
            </div>
            <div className="form-section_container-block_alert">
              <span>*We'll submit you only important updates</span>
            </div>
            {emailSent && (
              <div className="form-section_container-block_alert-email">
                <span>Check your email to confirm your registration!</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
